const gray = {
  25: "#FCFCFD",
  50: "#F9FAFB",
  75: "#B9B9C3",
  100: "#F2F4F7",
  200: "#EAECF0",
  300: "#D0D5DD",
  400: "#98A2B3",
  500: "#667085",
  600: "#475467",
  700: "#344054",
  800: "#1D2939",
  900: "#101828"
};

const blue = {
  25: "#F8FCFF",
  50: "#F1F9FF",
  100: "#D1ECFF",
  200: "#B1DFFF",
  300: "#8FD0FC",
  400: "#69B7EC",
  500: "#4C97CA",
  600: "#2F74A3",
  700: "#215D86",
  800: "#124364",
  900: "#082A42"
};

const error = {
  25: "#FFFBFA",
  50: "#FEF3F2",
  100: "#FEE4E2",
  200: "#FECDCA",
  300: "#FDA29B",
  400: "#F97066",
  500: "#F04438",
  600: "#D92D20",
  700: "#B42318",
  800: "#912018",
  900: "#7A271A"
};

const warning = {
  25: "#FFFCF5",
  50: "#FFFAEB",
  100: "#FEF0C7",
  200: "#FEDF89",
  300: "#FEC84B",
  400: "#FDB022",
  500: "#F79009",
  600: "#DC6803",
  700: "#B54708",
  800: "#93370D",
  900: "#7A2E0E"
};

const success = {
  25: "#F6FEF9",
  50: "#ECFDF3",
  100: "#D1FADF",
  200: "#A6F4C5",
  300: "#6CE9A6",
  400: "#32D583",
  500: "#12B76A",
  600: "#039855",
  700: "#027A48",
  800: "#05603A",
  900: "#054F31"
};

const logoColors = {
  LightBlue: "#49A8AF",
  DarkBlue: "#243357"
}

const green = {
  timerGreen: "#789950"
};

export { gray, blue, error, warning, success, green, logoColors };
